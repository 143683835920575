import * as React from 'react';
import {useEffect} from 'react';
import Cookies from 'js-cookie';
import {
  BASE_URL,
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REALM,
  KEYCLOAK_URL,
  CLIENT_COOKIE,
} from '../../common/constants';
import Keycloak, {KeycloakInitOptions} from 'keycloak-js';
import isLocalStorageAvailable from '../../common/utils/isLocalStorageAvailable';
import {
  B2CComponentsProvider,
  PageLoadingError,
} from '@edekadigital/b2c-components';
import {v4 as uuidV4} from 'uuid';

export const INITIALIZATION_ERROR_TITLE = 'Fehler beim Laden';
export const INITIALIZATION_ERROR_MESSAGE_COOKIES =
  'Diese Seite verwendet Cookies. Bitte aktivieren Sie Cookies in Ihrem Browser und versuchen sie es erneut.';

export const KeycloakLogout: React.FC = () => {
  useEffect(() => {
    const keycloak = new Keycloak({
      realm: KEYCLOAK_REALM,
      url: KEYCLOAK_URL,
      clientId: Cookies.get(CLIENT_COOKIE) ?? KEYCLOAK_CLIENT_ID,
    });

    // set some default if not present
    const params = new URLSearchParams(window.location.search);

    if (!params.has('code_challenge')) {
      //The value only needs to be present and does not have to be correct, as it will be renegotiated later by the Keycloak library
      params.set(
        'code_challenge',
        'I-tZ5uSY5p6bhP2mrOHN8pasc62jIrLyh_QaC8xnZZ8'
      );
    }
    if (!params.has('code_challenge_method')) {
      params.set('code_challenge_method', 'S256');
    }
    if (!params.has('response_type')) {
      params.set('response_type', 'code');
    }
    if (!params.has('scope')) {
      params.set('scope', 'openid');
    }
    if (!params.has('nonce')) {
      params.set('nonce', uuidV4());
    }
    if (!params.has('state')) {
      params.set('state', uuidV4());
    }
    const initOptions: KeycloakInitOptions = {
      onLoad: 'check-sso',
      pkceMethod: 'S256',
    };
    keycloak
      .init(initOptions)
      .then(() => {
        keycloak.logout({
          redirectUri: `${BASE_URL}${KEYCLOAK_URL}/realms/${KEYCLOAK_REALM}/protocol/openid-connect/auth?${params.toString()}`,
        });
      })
      .catch(() => {
        console.error('Logout error occurred');
        keycloak.clearToken();
      });
  }, []);
  return <></>;
};

const StartLogin: React.FC = () => {
  if (!isLocalStorageAvailable()) {
    return (
      <B2CComponentsProvider>
        <PageLoadingError
          title={INITIALIZATION_ERROR_TITLE}
          message={INITIALIZATION_ERROR_MESSAGE_COOKIES}
        />
      </B2CComponentsProvider>
    );
  } else {
    return <KeycloakLogout />;
  }
};

export default StartLogin;
